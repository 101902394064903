<template>
  <div>
    <div v-if="preview" @click="closePreview" class="preview-screen">
      <div v-if="preview.image" class="preview-container">
        <p class="justify-self pt-2"><b>Creative</b></p>
        <div class="d-flex justify-content-center align-items-center h-100">
          <img @click.stop class="preview-image image-fade" :src="preview.image" alt="img">
        </div>
      </div>
      <div v-if="preview.html" class="preview-container m-preview">
        <p class="justify-self pt-1"><b>HTML Snippet</b></p>
        <p class="preview-html">{{ preview.html }}</p>
      </div>
      <div v-if="preview.url" class="preview-container">
        <p class="justify-self pt-1"><b>IFrame</b></p>
        <iframe class="preview-iframe" :src="preview.url" frameborder="0"></iframe>
      </div>
    </div>
    <div class="overlay-form">
      <a-tooltip placement="top">
        <template slot="title">
          <span>Companion's name.</span>
        </template>
        <a-input class="overlay-input" v-model="companionName" type="text" placeholder="Companion Name"/>
      </a-tooltip>

      <a-tooltip placement="top">
        <template slot="title">
          <span>Creative to use as a static resource for companion.</span>
        </template>
        <a-select v-model="staticCreativeId" class="overlay-select" placeholder="Select ID">
          <a-select-option :value="-1" disabled>Select Creative</a-select-option>
          <a-select-option label="Select Banner" v-for="obj in creatives" :key="obj.Id">
            [{{obj.Id}}] {{ obj.Name }}
          </a-select-option>
        </a-select>
      </a-tooltip>

      <a-tooltip placement="top">
        <template slot="title">
          <span>URL of a resource that is an HTML page that can be displayed within an IFrame on the publisher's page.</span>
        </template>
        <a-input class="overlay-input" v-model="url" type="url" placeholder="IFrame URL"/>
      </a-tooltip>

      <a-tooltip placement="top">
        <template slot="title">
          <span>A "snippet" of HTML code to be inserted directly within the publisher's HTML page code.</span>
        </template>
        <!-- <a-input class="overlay-input" v-model="html" type="text" placeholder="HTML Snippet" /> -->
        <a-textarea
          :auto-size="{ minRows: 1 }"
          v-model="html"
          class="overlay-textarea"
          style="margin-bottom: 0.75em"
          placeholder="HTML Snippet"
        />
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>Width of the placement slot for which the companion creative is intended.</span>
        </template>
        <a-input class="overlay-input" v-model="width" type="number" placeholder="Width"/>
      </a-tooltip>

      <a-tooltip placement="top">
        <template slot="title">
          <span>Height of the placement slot for which the companion creative is intended.</span>
        </template>
        <a-input class="overlay-input" v-model="height" type="number" placeholder="Height"/>
      </a-tooltip>

      <a-tooltip placement="top">
        <template slot="title">
          <span>Destination URL that loads when user clicks a companion ad.</span>
        </template>
        <a-input class="overlay-input" v-model="destinationUrl" type="url" placeholder="Destination URL"/>
      </a-tooltip>

      <a-button class="px-2 py-0 submit-button" type="primary" @click="handleSubmit">Add +</a-button>
      <a-button class="mobile-submit-button" type="primary" @click="handleSubmit">Create Companion</a-button>
    </div>
    <!-- <div class="d-flex justify-content-center"> -->
    <!-- <a-button class="ml-1 px-2 py-0" type="primary" @click="handleSubmit">Create Companion</a-button> -->
    <!-- </div> -->
    <!-- list -->
    <b-table
      :bordered="true"
      :fields="fields"
      :hover="true"
      :items="companionList"
      :small="true"
      :striped="true"
      show-empty
      stacked="md"
      class="mt-4"
    >
      <template #cell(Preview)="data">
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click="openPreview(data.item.StaticCreativeId, data.item.Html, data.item.IframeUrl)">
          <i class="fa fa-eye"></i>
        </b-button>
      </template>
      <template #cell(Name)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Companion's name.</span>
            </template>
              <a-input-search class="overlay-input" placeholder="Companion Name" v-model="data.item.Name" size="small"
                              @search="updateCompanionName(data.item.Id, data.item.Name)">
                <a-button slot="enterButton">
                  <span style="fontSize: 90%;" class="material-icons-outlined">
                    done
                  </span>
                </a-button>
              </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
        <a-tooltip placement="top">
          <template slot="title">
            <span>Companion's name.</span>
          </template>
            <a-input-search class="overlay-input" placeholder="Companion Name" v-model="data.item.Name" size="small"
                            @search="updateCompanionName(data.item.Id, data.item.Name)">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
        </a-tooltip>
        </div>
      </template>
      <template #cell(Html)="data">
        <div v-if="data.item.Html">
          <span style="fontSize: 90%;" class="material-icons-outlined">
            done
          </span>
        </div>
      </template>
      <template #cell(DestinationUrl)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Destination URL that loads when user clicks a companion ad.</span>
            </template>
              <a-input-search class="overlay-input" placeholder="Destination URL" v-model="data.item.DestinationUrl"
                             size="small"
                              @search="updateCompanionDestination(data.item.Id, data.item.Name, data.item.DestinationUrl)">
                <a-button slot="enterButton">
                  <span style="fontSize: 90%;" class="material-icons-outlined">
                    done
                  </span>
                </a-button>
              </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Destination URL that loads when user clicks a companion ad.</span>
            </template>
            <a-input-search class="overlay-input" placeholder="Destination URL" v-model="data.item.DestinationUrl"
                           size="small"
                            @search="updateCompanionDestination(data.item.Id, data.item.Name, data.item.DestinationUrl)">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click="onDelete(data.item.Id)">
          <i class="fa fa-trash"></i>
        </b-button>
      </template>
      <template #cell(StaticCreativeId)="data">
        <div v-if="data.item.StaticCreativeId">
          <span style="fontSize: 90%;" class="material-icons-outlined">
            done
          </span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { Modal } from 'ant-design-vue'
import { createCompanion, deleteCompanion, updateCompanion } from '@/api/display/companion'

export default {
  props: ['id', 'type', 'creatives', 'companions', 'labelCol', 'wrapperCol'],
  data() {
    return {
      companionName: null,
      staticCreativeId: -1,
      url: null,
      html: null,
      width: null,
      height: null,
      destinationUrl: null,
      preview: false,
      companionList: this.companions,
      // creatives: [],
      fields: [
        {
          key: 'Preview',
          label: 'Preview',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          class: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          headerTitle: 'Name',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width: 220px',
        },
        {
          key: 'StaticCreativeId',
          label: 'Creative',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
        {
          key: 'Html',
          label: 'HTML',
          show: true,
          sortable: false,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
        {
          key: 'IframeUrl',
          label: 'IFrame URL',
          tdClass: 'text-md-left text-center',
          show: true,
          sortable: false,
        },
        {
          key: 'Width',
          label: 'Width',
          show: true,
          headerTitle: 'Zone ID',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width:70px',
        },
        {
          key: 'Height',
          label: 'Height',
          show: true,
          headerTitle: 'Zone ID',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width:70px',
        },
        {
          key: 'DestinationUrl',
          label: 'Destination URL',
          show: true,
          headerTitle: 'Min Duration',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px; width:220px;',
        },
        {
          key: 'actions',
          label: '',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
      ],
    }
  },
  methods: {
    openPreview(id, html, url) {
      const found = this.creatives.find((el) => el.Id === id)
      if (found !== undefined) {
        this.preview = {
          image: found.Cdn,
          html: html,
          url: url,
        }
        this.preview.image = found.Cdn
      } else {
        this.preview = {
          html: html,
          url: url,
        }
      }
    },
    closePreview() {
      this.preview = false
    },
    handleChange(val, e) {
      this.val = e.target.value
    },
    updateCompanionName(Id, Name) {
      // MediaRating = parseInt(MediaRating)
      if (Name !== null) {
        const payload = {
          Id,
          Name,
        }
        updateCompanion(payload)
        Vue.prototype.$notification.success({
          message: 'Companion Updated.',
          description: `Companion: ${Name} Updated.`,
        })
      } else {
        Vue.prototype.$notification.warning({
          message: 'Name is required.',
        })
      }
    },
    updateCompanionDestination(Id, Name, DestinationUrl) {
      // MediaRating = parseInt(MediaRating)
      const payload = {
        Id,
        DestinationUrl,
      }
      updateCompanion(payload)
      Vue.prototype.$notification.success({
        message: 'Companion Updated.',
        description: `Companion: ${Name} Updated.`,
      })
    },
    onDelete(Id) {
      Modal.confirm({
        title: 'Are you sure you want to delete this companion?',
        onOk: () => {
          deleteCompanion(Id)
          const index = this.companionList.findIndex(el => el.Id === Id)
          this.companionList.splice(index, 1)
          // this.companionList.splice(Id, 1)
        },
      })
    },
    handleSubmit() {
      if ((this.companionName !== null && this.width !== null && this.height !== null && this.destinationUrl !== null) && (this.staticCreativeId !== null || this.html !== null || this.url !== null)) {
        // if (this.staticCreativeId === -1) {
        //   this.staticCreativeId = null
        // }
        const values = {
          VideoadId: this.type === 'ad' ? this.id : null,
          RemotevideozoneId: this.type === 'zone' ? this.id : null,
          Name: this.companionName,
          StaticCreativeId: this.staticCreativeId === -1 ? null : this.staticCreativeId,
          IframeUrl: this.url,
          Html: this.html,
          Width: this.width,
          Height: this.height,
          DestinationUrl: this.destinationUrl,
        }
        createCompanion(values).then((res) => {
          if (res.Companion) {
            this.companionList.push(res.Companion)
            this.companionName = null
            this.staticCreativeId = -1
            this.url = null
            this.html = null
            this.width = null
            this.height = null
            this.destinationUrl = null
          }
        })
      } else if (this.companionName === null || this.width === null || this.height === null || this.destinationUrl === null) {
        console.log('2')
        this.staticCreativeId = this.staticCreativeId !== -1 || this.staticCreativeId !== null ? this.staticCreativeId : -1
        Vue.prototype.$notification.warning({
          message: 'Not enough information.',
          description: 'Companion Name, Width, Height and Destination URL are required.',
        })
      }
    },
  },
  watch: {
    staticCreativeId(val) {
      this.creatives.forEach(element => {
        if (element.Id === val) {
          this.width = element.Width
          this.height = element.Height
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/custom/companions/partials.scss';

.ant-form-item {
  margin-bottom: 0px;
}
</style>
